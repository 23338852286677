import { useCopy, usePropsObject } from "@/lib/contexts/appContext"
import { useTemplate } from "@/lib/helpers/template"
import Logo from "@/common/logos/logo"

import Primary from "./menus/primary"
import Template from "./template"
import Content from "./content"
import Newsletter from "./newsletter"
import styles from "./footer.module.css"
import classNames from "classnames"

export default function Footer() {
  const copy = useCopy()
  const object = usePropsObject()
  const blockTemplate = useTemplate()
  const { template, hideForm } = copy.footer
  const { year, publisher } = copy.general

  const lastBlock = () => {
    return template.length > 0 && template[template.length - 1]
  }

  const classes = [
    styles.footer,
    lastBlock()?.name === "core/group" ? lastBlock().attrs.theme : "",
    {
      "has-related":
        blockTemplate === "article" && object?.related?.posts?.length > 0
    }
  ]

  return (
    <footer className={classNames(classes)}>
      {<Template content={template} />}
      {!hideForm && <Newsletter />}
      <div className="inner-wrapper">
        <div className="logos" role="logos">
          <Logo className="logo" />
        </div>
        <Primary />
        <Content />
        <div className="bottom-row" role="wrapper">
          <span className="publisher">
            &copy; {year} {publisher}
          </span>
        </div>
      </div>
    </footer>
  )
}
