import { useCopy } from "@/lib/contexts/appContext"
import Social from "@/common/social/social"
import styles from "./content.module.css"

export default function Content() {
  const copy = useCopy()
  const { content } = copy.footer

  if (!content || content.length === 0) {
    return null
  }

  return (
    <div className={styles.content}>
      {content.map((item) => {
        return (
          <div key={item.heading} className="section">
            <h4>{item.heading}</h4>
            <div className="columns">
              <div
                className="column wysiwyg-content"
                dangerouslySetInnerHTML={{ __html: item.content_left }}
              />
              {item.right_type === "content" && (
                <div
                  className="column wysiwyg-content"
                  dangerouslySetInnerHTML={{ __html: item.content_right }}
                />
              )}
              {item.right_type === "socialmedia" && (
                <Social styles={styles} location="primary" />
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}
