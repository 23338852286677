import useMenuItems from "@/lib/helpers/menu"
import Link from "@/components/common/menu/link"
import styles from "./top.module.css"

export default function MenuTop({ hasMounted, isNarrow }) {
  const location = "top-bramiljoval"
  const items = useMenuItems(location)
  if (!items) {
    return null
  }

  if (hasMounted && isNarrow) {
    return null
  }

  return (
    <ul className={styles.menu} role={location + "-menu"}>
      {items.map((item) => (
        <li key={item.url}>
          <Link item={item} location={location} />
        </li>
      ))}
    </ul>
  )
}
