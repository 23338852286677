import { useEffect, useState } from "react"
import { usePostType } from "@/lib/helpers/template"
import {
  usePathData,
  usePropsObject,
  useAppContext,
  useAdminBar
} from "@/lib/contexts/appContext"
import Main from "@/components/wrappers/main"
import Breadcrumbs from "@/components/base/breadcrumbs/breadcrumbs"
import Blocks from "@/components/Blocks"
import { ArchiveProvider } from "@/lib/contexts/archiveContext"
import Byline from "@/common/article/byline"
import ArticleCard from "@/common/cards/article/article"
import ProductCard from "@/common/cards/product/product"
import CTA from "@/common/article/cta"
import Terms from "@/common/article/terms"
import SearchPanel from "@/common/search-panel/search-panel"
import Like from "@/common/social/like"
import Share from "@/common/social/share"
import styles from "./article.module.css"
import useMenuItems from "@/lib/helpers/menu"
import classNames from "classnames/bind"
import { pushDataLayer } from "@/lib/helpers/tagManager"

export default function Article() {
  const object = usePropsObject()
  const pathData = usePathData()
  const appContext = useAppContext()
  const postType = usePostType()
  const adminBar = useAdminBar()
  const hasTopMenu = !!useMenuItems("top-bramiljoval")
  const cx = classNames.bind(styles)
  const [classes, setClasses] = useState({
    main: true,
    "has-search": postType === "school",
    "has-admin-bar": adminBar,
    "has-top-menu": hasTopMenu
  })

  useEffect(() => {
    setClasses({
      ...classes,
      "has-search": postType === "school",
      "has-admin-bar": adminBar,
      "has-top-menu": hasTopMenu
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathData])

  const primary = object.terms.filter((term) => term.isPrimary === true).shift()
  const crumbs = [{ label: object.title, uri: null }]
  if (primary) {
    crumbs.unshift({ label: primary.label, uri: primary.url })
  }

  crumbs.unshift({ label: "Startsida", uri: "/" })

  const related = object.related.posts.map((post, key) => {
    const Card = postType === "bmv-product" ? ProductCard : ArticleCard

    return (
      <li key={key}>
        <Card post={post} classes="mb-0" />
      </li>
    )
  })

  useEffect(() => {
    pushDataLayer(object.tracking)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Main className={cx(classes)}>
      {postType === "school" && (
        <ArchiveProvider
          context="school"
          object={object}
          phrase={appContext.phrase}
          selected={appContext.selected}>
          <SearchPanel />
        </ArchiveProvider>
      )}
      <Breadcrumbs crumbs={crumbs} gap="none" />
      <article className={styles.article}>
        <Blocks blocks={pathData.content} />
      </article>
      <CTA data={object.cta} />
      <Terms
        terms={object.terms}
        className="max-w-screen-sm px-4 mt-10 sm:px-10 md:max-w-1xl"
      />
      <div className="box-content items-center justify-between max-w-screen-sm px-4 mx-auto mt-6 mb-6 sm:flex sm:px-10 md:max-w-1xl post-social no-print">
        <Like id={object.id} likes={object.likes} />
        <div className="flex">
          <Share classes="mt-4 sm:mt-0 mr-2 w-icon h-icon" url={object.url} />
        </div>
      </div>
      {object.byline && <Byline byline={object.byline} />}
      {related.length > 0 && (
        <div className={classNames(styles["related-wrapper"], "no-print")}>
          <div className="inner-wrapper">
            <h2 className="mb-8 text-xl sm:text-2xl lg:text-3xl">
              {object.related.label}
            </h2>
            <ul className="list">{related}</ul>
          </div>
        </div>
      )}
    </Main>
  )
}
