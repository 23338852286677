import { useEffect, useState } from "react"
import {
  usePathData,
  usePropsObject,
  useAppContext,
  useAdminBar
} from "@/lib/contexts/appContext"
import useMenuItems from "@/lib/helpers/menu"
import Main from "@/components/wrappers/main"
import Blocks from "@/components/Blocks"
import { ArchiveProvider } from "@/lib/contexts/archiveContext"
import styles from "./archive.module.css"
import classNames from "classnames"

export function Archive() {
  const pathData = usePathData()
  const adminBar = useAdminBar()
  const hasTopMenu = !!useMenuItems("top-bramiljoval")
  const [classes, setClasses] = useState([
    "template-" + pathData.template,
    "text-black-100"
  ])

  const object = usePropsObject()
  const appContext = useAppContext()

  useEffect(() => {
    setClasses([
      ...classes,
      {
        "has-admin-bar": adminBar,
        "has-top-menu": hasTopMenu
      }
    ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathData])

  const { list, context, maxPage, totalCount, cardType } = object.archive

  return (
    <Main className={classNames(classes, styles.main)}>
      <ArchiveProvider
        object={object}
        context={context}
        list={list}
        maxPage={maxPage}
        totalCount={totalCount}
        cardType={cardType}
        page={object.page}
        phrase={appContext.phrase}
        selected={appContext.selected}>
        <Blocks blocks={pathData.content} />
      </ArchiveProvider>
    </Main>
  )
}

export default Archive
