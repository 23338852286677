import Article from "@/templates/article/article"
import Page from "@/templates/page/page"
import Archive from "@/templates/archive/archive"

import Error from "@/templates/error/error"
import Protected from "@/templates/protected/protected"

export const templateMapping = {
  article: Article,
  page: Page,
  archive: Archive,
  error: Error,
  protected: Protected
}
