async function fetchAPI(query, { variables } = {}, headers = {}) {
  headers = { ...headers, "Content-Type": "application/json" }
  const apiURL = process.env.NEXT_PUBLIC_API_URL ?? process.env.API_URL

  try {
    const res = await fetch(apiURL, {
      method: "POST",
      headers,
      body: JSON.stringify({
        query,
        variables
      })
    })

    const json = await res.json()

    if (json.errors) {
      console.error({ query: query, errors: json.errors })
      return json.errors
    }

    return json.data
  } catch (error) {
    console.log("error", error)
    return error
  }
}

export async function fetchPaths() {
  const data = await fetchAPI(
    `query {
        fetchPaths {
          slug
        }
      }`
  )

  return data.fetchPaths
}

export async function fetchPathData({ path, isPreview, password, id }, token) {
  const headers = token ? { Authorization: `Bearer ${token}` } : null
  path = !isPreview ? "/bra-miljoval" + path : null

  const data = await fetchAPI(
    `
    query ($path: String, $isPreview: Boolean, $password: String, $id: Int) {
      fetchPathData(path: $path, isPreview: $isPreview, password: $password, id: $id) {
        template
        path
        status
        redirect
        head {
          tag
          attrs
        }
        menus {
          location
          items {
            url
            target
            label
            items {
              url
              target
              label
              items {
                url
                target
                label
              }
            }
          }
        }
        copy {
          location
          strings
        }
        meta
        props {
          location
          content
        }
        content {
          name
          text
          html
          attrs
          innerBlocks {
            name
            text
            html
            attrs
            innerBlocks {
              name
              text
              html
              attrs
              innerBlocks {
                name
                text
                html
                attrs
                innerBlocks {
                  name
                  text
                  html
                  attrs
                }
              }
            }
          }
        }
      }
    }`,
    {
      variables: { path, isPreview, password, id }
    },
    headers
  )

  return data.fetchPathData
}

export async function requestArticleLike(id, action, token) {
  const data = await fetchAPI(
    `mutation($id: ID!, $action: LikeAction!, $token: String!) {
        articleLike(id: $id, action: $action, token: $token) {
          status
          likes
        }
      }`,
    {
      variables: { id, action, token }
    },
    true
  )

  return data.articleLike
}

export async function fetchLikeCount(id) {
  const { fetchLikeCount } = await fetchAPI(
    `query($id: ID!) {
        fetchLikeCount(id: $id) {
          likes
        }
      }`,
    {
      variables: { id }
    }
  )
  return fetchLikeCount
}

export async function searchContent(string, context, filters) {
  const { searchContent } = await fetchAPI(
    `query($string: String, $context: searchContext!, $filters: JSON!) {
        searchContent(string: $string, context: $context, filters: $filters) {
          result
          totalCount
          maxPage
          cardType
          hash
        }
      }`,
    {
      variables: { string, context, filters: filters }
    }
  )

  return searchContent
}

export async function fetchLatestArticles(context, page) {
  const { fetchLatestArticles } = await fetchAPI(
    `query($context: String!, $page: Int!) {
        fetchLatestArticles(context: $context, page: $page) {
          articles
        }
      }`,
    {
      variables: { context, page }
    }
  )

  return fetchLatestArticles
}

export async function uploadToGravityForm(
  form_id,
  field_id,
  gform_unique_id,
  file
) {
  const GFapiURL =
    process.env.NEXT_PUBLIC_WP_JSON_API_URL + "triggerfish/gravity-forms"
  const splitedFieldId = field_id.split("_")
  let formData = new FormData()
  formData.append("file", file, file.name)
  formData.append("form_id", form_id)
  formData.append("gform_unique_id", gform_unique_id)
  formData.append("original_filename", file.name)
  formData.append("name", file.name)
  formData.append("field_id", splitedFieldId[1])

  try {
    const res = await fetch(GFapiURL + "/upload", {
      method: "POST",
      body: formData
    })
    const json = await res.text()
    return JSON.parse(json)
  } catch (error) {
    console.log("error", error)
    return error
  }
}

export async function createGravityFormEntry(id, data, token) {
  const { createGravityFormEntry } = await fetchAPI(
    `mutation($id: ID!, $data: JSON!, $token: String!) {
          createGravityFormEntry(id: $id, data: $data, token: $token){
            error
            data
          }
        }`,
    {
      variables: { id, data: JSON.stringify(data), token }
    }
  )

  return createGravityFormEntry
}

export async function newsletterSignup(email, token) {
  const { status } = await fetchAPI(
    `mutation($email: String!, $token: String!) {
        newsletterSignup(email: $email, token: $token) {
          status
        }
      }`,
    {
      variables: { email, token }
    }
  )

  return status
}

export async function fetchPostPath(id) {
  const {
    fetchPreviewPostPath: { path }
  } = await fetchAPI(
    `query($id: Int!) {
        fetchPreviewPostPath(id: $id) {
          path
        }
      }`,
    {
      variables: { id }
    }
  )

  return path
}

export async function authEditor({ user, password }) {
  const { authEditor } = await fetchAPI(
    `mutation($user: String!, $password: String!) {
        authEditor(user: $user, password: $password) {
          user {
            login
            name
            email
            avatar
          }
          status
          credentials {
            type
            token
          }
        }
      }`,
    {
      variables: {
        user,
        password
      }
    }
  )

  return authEditor
}

export async function fetchFormData(form, token) {
  const data = await fetchAPI(
    `query($form: String!, $token: String!) {
      fetchFormData(form: $form, token: $token) {
        success,
        data,
      }
    }`,
    {
      variables: { form, token }
    },
    true
  )

  return data
}

export async function tickSearchClick(token, hash) {
  if (!hash) {
    return null
  }
  const data = await fetchAPI(
    `mutation($token: String!, $hash: String!) {
        tickSearchClick(token: $token, hash: $hash) {
          status
        }
      }`,
    {
      variables: { token, hash }
    },
    true
  )

  return data.tickSearchClick
}

export async function validateEditor(token) {
  const { validateEditor } = await fetchAPI(
    `mutation($token: String!) {
      validateEditor(token: $token) {
        user {
          login
          name
          email
          avatar
        }
        status
        credentials {
          type
          token
        }
        }
      }`,
    {
      variables: { token }
    },
    true
  )

  return validateEditor
}
