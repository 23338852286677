import dynamic from "next/dynamic"
import { useCopy } from "@/lib/contexts/appContext"
import classNames from "classnames"

const icons = {
  facebook: dynamic(() => import("@/components/common/icons/facebook")),
  twitter: dynamic(() => import("@/components/common/icons/twitter")),
  linkedin: dynamic(() => import("@/components/common/icons/linkedin")),
  youtube: dynamic(() => import("@/components/common/icons/youtube")),
  instagram: dynamic(() => import("@/components/common/icons/instagram"))
}

export default function Social({ location, styles }) {
  const copy = useCopy()
  const { socialMedia } = copy.general
  const theme = location === "primary" ? "dark" : "light"
  const { heading, links } = socialMedia

  const Icon = ({ service, ...props }) => {
    const Icon = icons[service] ?? NoIcon
    return <Icon {...props} />
  }

  return (
    <div className="social">
      <span className={styles?.heading}>{heading}</span>
      {links && links.length > 0 && (
        <ul className={classNames([styles?.social, theme])}>
          {links.map((link) => {
            return (
              <li key={link.url}>
                <a href={link.url} rel="noreferrer noopener" target="_blank">
                  <Icon
                    className={classNames(["service", link.service])}
                    service={link.service}
                  />
                </a>
              </li>
            )
          })}
        </ul>
      )}
    </div>
  )
}

export function NoIcon() {
  return <div></div>
}
