import ThumbsUp from "../icons/thumbsup"
import React, { useState, useEffect, useRef } from "react"
import Storage from "../../../lib/storage"
import { requestArticleLike, fetchLikeCount } from "../../../lib/api"
import ReCAPTCHA from "react-google-recaptcha"

const isLiked = (id) => {
  let userLikes = Storage.get("likes")
  if (typeof userLikes === "undefined") {
    Storage.set("likes", [])
    return false
  }

  return userLikes.indexOf(id) > -1
}

export default function Like({ id, likes }) {
  const dim = "bg-summer-green-50 hover:bg-summer-green-50 border-yellow-100"
  const [count, setCount] = useState(likes)
  const [classes, setClasses] = useState("")
  const [disabled, setDisabled] = useState(false)
  const [error, setError] = useState(false)
  const [liked, setLiked] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const recaptchaRef = useRef()
  const siteKey = process.env.NEXT_PUBLIC_CAPTCHA_SITE_KEY

  useEffect(() => {
    setLiked(isLiked(id))

    const response = requestLikeCount(id)
    if (!isLoading) {
      setCount(response.like)
    }
  }, [])

  useEffect(() => {
    if (isLiked(id) && !liked) {
      setLiked(true)
    } else if (!isLiked(id) && liked) {
      setLiked(false)
    }
  }, [liked])

  useEffect(() => {
    setDisabled(false)
    setLiked(!liked)
  }, [count])

  useEffect(() => {
    setClasses(liked ? dim : "")
  }, [liked])

  const requestLikeCount = async () => {
    const response = await fetchLikeCount(id)

    setIsLoading(false)
    return response
  }

  const requestLike = async () => {
    setDisabled(true)

    const token = await recaptchaRef.current.executeAsync()

    const action = liked ? "remove" : "add"
    const response = await requestArticleLike(id, action, token)

    if (response?.status === "ok") {
      let userLikes = Storage.get("likes", [])

      if (action === "add") {
        userLikes.push(id)
      } else {
        userLikes = userLikes.filter((like) => id !== like)
      }

      Storage.set("likes", userLikes)
      setCount(response.likes)
    } else {
      setDisabled(false)
      setError(true)
      setClasses("bg-warning")
    }
  }

  const Text = () => {
    if (error) {
      return "Något gick snett."
    }

    return count === 0 ? "Bli först att gilla!" : "Gillas av " + count
  }

  return (
    <div className="flex items-center">
      <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={siteKey} />
      <span
        onClick={disabled ? null : requestLike}
        className={[
          "hover:border-summer-green-100 hover:bg-summer-green-20 flex items-center justify-center border border-black-100/20 rounded-full w-icon-lg h-icon-lg cursor-pointer",
          classes,
          disabled ? "cursor-wait" : ""
        ].join(" ")}>
        {!error && <ThumbsUp isLiked={liked} />}
      </span>
      <span className="ml-3 font-sans text-sm">
        <Text />
      </span>
    </div>
  )
}
