import Twitter from "../icons/twitter"
import Facebook from "../icons/facebook"
import LinkedIn from "../icons/linkedin"
import Email from "../icons/email"
import Print from "../icons/print"

export default function Share({
  wrapper = "",
  classes = "w-icon h-icon",
  url,
  label = "",
  iconSize = "small",
  hidePrint = false
}) {
  if (typeof url === "undefined") {
    return null
  }

  classes = [
    "block bg-black-100/10 hover:bg-black-100/20 rounded-full flex justify-center items-center",
    classes
  ]

  const labelElem =
    label.length === 0 ? (
      ""
    ) : (
      <li className="mb-3 font-sans text-base font-bold tracking-wider text-center uppercase">
        {label}
      </li>
    )

  let icons = {
    small: {
      twitter: "w-6",
      facebook: "w-4",
      linkedin: "w-4",
      email: "w-4 h-4"
    },
    large: {
      twitter: "w-8",
      facebook: "w-6",
      linkedin: "w-5",
      email: "w-5 h-5"
    }
  }

  let services = [
    {
      url: "https://twitter.com/intent/tweet?url=",
      icon: <Twitter className={icons[iconSize].twitter} />
    },
    {
      url: "https://www.facebook.com/share.php?u=",
      icon: <Facebook className={icons[iconSize].facebook} />
    },
    {
      url: "https://www.linkedin.com/sharing/share-offsite/?url=",
      icon: <LinkedIn className={icons[iconSize].linkedin} />
    },
    {
      url: "mailto:?body=",
      icon: <Email className={icons[iconSize].email} />
    },
    {
      fn: "print",
      url: null,
      icon: <Print className="w-5 h-4" />
    }
  ]

  services = services.map((service, key) => {
    if (service?.fn === "print") {
      if (hidePrint) {
        return null
      }

      return (
        <li key={key}>
          <a
            onClick={() => {
              window.print()
            }}
            className={classes.join(" ")}>
            {service.icon}
          </a>
        </li>
      )
    }

    return (
      <li key={key}>
        <a
          className={classes.join(" ")}
          href={service.url + encodeURI(url)}
          rel="noreferrer"
          target="_blank">
          {service.icon}
        </a>
      </li>
    )
  })

  return (
    <ul className={["sharing", "flex", "no-print", wrapper].join(" ")}>
      {labelElem}
      {services}
    </ul>
  )
}
