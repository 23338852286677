import { useEffect, useState } from "react"
import Link from "./link"
import Menu from "./menu"
import { Caret } from "@/components/common/icons/caret"
import classNames from "classnames"

export default function Item({
  item,
  className,
  location,
  parentOpen = false,
  caret = false,
  hover = false,
  depth = 0
}) {
  const hasSubMenu = item?.items?.length > 0
  const subClass = Array(depth).fill("sub-")
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (hasSubMenu && !parentOpen && open) {
      setOpen(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentOpen])

  return (
    <li
      className={className}
      onMouseEnter={() => hover && hasSubMenu && setOpen(true)}
      onMouseLeave={() => hover && hasSubMenu && setOpen(false)}>
      <Link item={item} location={location}>
        {hasSubMenu && (
          <div
            className="toggle"
            onClick={(e) => {
              e.preventDefault()
              !hover && setOpen(!open)
            }}>
            <Caret className={classNames({ caret: true, open: open })} />
          </div>
        )}
        {caret && (
          <Caret className={classNames({ caret: true, right: true })} />
        )}
      </Link>
      {hasSubMenu && open && (
        <Menu
          menuClasses={classNames([subClass + "sub-menu", { "is-open": open }])}
          items={item.items}
          parentOpen={open}
          hover={hover && depth === 1}
          depth={depth + 1}
          location={"sub-" + location}
        />
      )}
    </li>
  )
}
