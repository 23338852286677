import useMenuItems from "@/lib/helpers/menu"
import Menu from "@/components/common/menu/simple"
import styles from "./menu.module.css"

export default function Primary() {
  const location = "primary-footer-bramiljoval"
  const items = useMenuItems(location)

  return <Menu menuClasses={styles.menu} items={items} location={location} />
}
