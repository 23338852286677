import useMenuItems from "@/lib/helpers/menu"
import Menu from "@/components/common/menu/menu"
import styles from "./featured.module.css"

export default function MenuFeatured({ hasMounted, isNarrow, handleOverlay }) {
  const location = "primary-bramiljoval"
  const items = useMenuItems(location)

  if (!items) {
    return null
  }

  if (hasMounted && isNarrow) {
    return null
  }

  return (
    <Menu
      menuClasses={styles.menu}
      handleOverlay={handleOverlay}
      items={items}
      location={location}
      hover={true}
    />
  )
}
