export default function LogoSpinner({
  className = "mb-6 fill-current",
  wrapperClass = "relative"
}) {
  return (
    <div className={wrapperClass}>
      <svg
        version="1.1"
        id="circle"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 100 100"
        className="absolute">
        <circle
          fill="none"
          stroke="black"
          strokeWidth="4"
          strokeMiterlimit="0"
          cx="50"
          cy="50"
          r="48"
          strokeDasharray="360"
          strokeLinecap="round"
          transform="rotate(-90 ) translate(-100 0)">
          <animate
            attributeName="stroke-dashoffset"
            values="360;0"
            dur="1s"
            repeatCount="indefinite"></animate>
        </circle>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="50.106"
        height="50.106"
        viewBox="0 0 13.257 13.257"
        className={className}>
        <path
          d="M6.615 13.163c-3.602 0-6.549-2.947-6.549-6.548C.066 3.013 3.013.066 6.615.066c3.601 0 6.548 2.947 6.548 6.549 0 3.601-2.947 6.548-6.548 6.548zm0-12.593C3.29.57.57 3.29.57 6.615c0 3.324 2.72 6.044 6.045 6.044 3.324 0 6.044-2.72 6.044-6.044C12.66 3.29 9.94.57 6.615.57z"
          className="opacity-30"
        />
        <path d="M9.587 6.514c.15-.176.755-.126.755-.126.353 0 .378-.252.302-.428-.075-.177-.302-.554-.705-.907-.478-.428-1.032-.756-1.435-.982-.429-.202-1.008-.504-1.486-.73-.454-.202-1.21-.53-1.638-.655-.428-.152-.503-.177-.554-.177-.05 0-.075.025-.05.05.076.051.831.68 1.108.907.277.227.554.53.756.73.15.177.302.328.327.379 0 0 0 .025-.025.05-.151.076-.655.378-.655.378-.126.075-.755-.126-1.234-.252-.479-.151-1.688-.58-1.864-.605-.15-.05-.277-.075-.478.202-.202.277-.151.403-.026.504.101.075.403.277.882.579.479.302.982.655 1.234.831.126.076.403.302.428.428v.63c0 .076 0 .151-.025.176-.025.05-.076.05-.076.05-.83 0-1.586-.15-2.09-.251-.529-.101-1.285-.302-1.335-.302-.05 0-.076.025-.05.05.15.151.881.806 2.115 1.536 2.015 1.21 3.224 1.335 3.677 1.386.328.025.806-.026 1.033-.101a.964.964 0 00.479-.328c.05-.075.075-.176.05-.302-.025-.126-.1-.176-.252-.327-.126-.151-.151-.378-.176-.479.352-.076.831-.126 1.108-.15a.948.948 0 01.504.075c.025.025.075.05.075.05s.05.025.05-.025v-.151h.102c.05 0 .176.05.176.05 0-.05.025-.1 0-.15 0-.102-.076-.177-.126-.253a.707.707 0 00-.176-.478c-.151-.177-.53-.479-.831-.73.05-.026.075-.101.126-.152z" />
      </svg>
    </div>
  )
}
