export default function FilterIcon({ className = "w-4" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 18"
      xmlSpace="preserve"
      className={className}>
      <g>
        <path
          d="M11,18c-0.2,0-0.4-0.1-0.6-0.2L6.5,15C6.2,14.8,6,14.5,6,14.2V9.3L0.2,1.6C0,1.3-0.1,0.9,0.1,0.6S0.6,0,1,0h16
     c0.4,0,0.7,0.2,0.9,0.6s0.1,0.7-0.1,1L12,9.3V17c0,0.4-0.2,0.7-0.5,0.9C11.3,18,11.1,18,11,18z M8,13.7L10,15V9
     c0-0.2,0.1-0.4,0.2-0.6L15,2H3l4.8,6.4C8,8.6,8,8.8,8,9V13.7z"
        />
      </g>
    </svg>
  )
}
