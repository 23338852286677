import { usePathData, usePropsObject } from "@/lib/contexts/appContext"

export function useIsBraMiljoval() {
  const object = usePropsObject()

  return object?.theme === "bra-miljoval"
}

export function useHasHero() {
  const object = usePropsObject()
  const hero = object?.hasHero

  return hero ?? false
}

export function useIsStart() {
  const pathData = usePathData()
  const isBMV = useIsBraMiljoval()

  const startURI = !isBMV ? "/" : "/bra-miljoval/"

  return pathData.path === startURI
}

export function useTemplate() {
  const { template } = usePathData()

  return template
}

export function usePostType() {
  const object = usePropsObject()

  return object?.type
}

export function useFirstBlock() {
  const { content } = usePathData()

  if (content === undefined || content.length === 0) {
    return false
  }

  if (content[0]?.name === "theme/useragent-conditional-content") {
    return content[0]?.innerBlocks[0] ?? content[0]
  }

  return content[0]
}

export function useHasBlock(blockName) {
  const { content } = usePathData()

  if (content === undefined || content.length === 0) {
    return false
  }

  const hasBlock = content.filter((block) => block.name === blockName)

  return hasBlock.length > 0
}

export function useLastBlock() {
  const { content } = usePathData()
  const length = content.length

  if (content === undefined || length === 0) {
    return false
  }

  return content[length - 1]
}
