import classNames from "classnames"
import { siteLogo } from "./logo.module.css"

export default function Logo({ className = "text-black-100" }) {
  const classes = ["fill-current", className, siteLogo]

  return (
    <svg
      viewBox="0 0 200 52"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(classes)}>
      <g fillRule="evenodd">
        <g>
          <path d="M26,52 C11.7,52 0,40.3 0,26 C0,11.7 11.7,0 26,0 C40.3,0 52,11.7 52,26 C52,40.3 40.3,52 26,52 Z M26,2 C12.8,2 2,12.8 2,26 C2,39.2 12.8,50 26,50 C39.2,50 50,39.2 50,26 C50,12.8 39.2,2 26,2 Z" />
          <path d="M37.8,25.6 C38.4,24.9 40.8,25.1 40.8,25.1 C42.2,25.1 42.3,24.1 42,23.4 C41.7,22.7 40.8,21.2 39.2,19.8 C37.3,18.1 35.1,16.8 33.5,15.9 C31.8,15.1 29.5,13.9 27.6,13 C25.8,12.2 22.8,10.9 21.1,10.4 C19.4,9.8 19.1,9.7 18.9,9.7 C18.7,9.7 18.6,9.8 18.7,9.9 C19,10.1 22,12.6 23.1,13.5 C24.2,14.4 25.3,15.6 26.1,16.4 C26.7,17.1 27.3,17.7 27.4,17.9 C27.4,17.9 27.4,18 27.3,18.1 C26.7,18.4 24.7,19.6 24.7,19.6 C24.2,19.9 21.7,19.1 19.8,18.6 C17.9,18 13.1,16.3 12.4,16.2 C11.8,16 11.3,15.9 10.5,17 C9.7,18.1 9.9,18.6 10.4,19 C10.8,19.3 12,20.1 13.9,21.3 C15.8,22.5 17.8,23.9 18.8,24.6 C19.3,24.9 20.4,25.8 20.5,26.3 C20.5,27.3 20.5,28.5 20.5,28.8 C20.5,29.1 20.5,29.4 20.4,29.5 C20.3,29.7 20.1,29.7 20.1,29.7 C16.8,29.7 13.8,29.1 11.8,28.7 C9.7,28.3 6.7,27.5 6.5,27.5 C6.3,27.5 6.2,27.6 6.3,27.7 C6.9,28.3 9.8,30.9 14.7,33.8 C22.7,38.6 27.5,39.1 29.3,39.3 C30.6,39.4 32.5,39.2 33.4,38.9 C34.4,38.6 35.1,37.9 35.3,37.6 C35.5,37.3 35.6,36.9 35.5,36.4 C35.4,35.9 35.1,35.7 34.5,35.1 C34,34.5 33.9,33.6 33.8,33.2 C35.2,32.9 37.1,32.7 38.2,32.6 C39.3,32.5 40,32.8 40.2,32.9 C40.3,33 40.5,33.1 40.5,33.1 C40.5,33.1 40.7,33.2 40.7,33 C40.7,32.8 40.7,32.4 40.7,32.4 C40.7,32.4 40.9,32.4 41.1,32.4 C41.3,32.4 41.8,32.6 41.8,32.6 C41.8,32.4 41.9,32.2 41.8,32 C41.8,31.6 41.5,31.3 41.3,31 C41.3,30.3 41.1,29.7 40.6,29.1 C40,28.4 38.5,27.2 37.3,26.2 C37.5,26.1 37.6,25.8 37.8,25.6 L37.8,25.6 Z" />
        </g>
        <path d="M72.7,27.7 C73.9,27.2 75,26.1 75,24.2 C75,22.9 74.5,21.8 73.6,21 C72.3,19.9 69.4,19.8 69.3,19.8 L62,19.8 L62,36.7 L67.3,36.7 C70,36.7 70.6,36.7 71.4,36.6 C75.3,36 75.6,32.8 75.6,31.8 C75.6,29.9 74.5,28.4 72.7,27.7 Z M64.1,21.8 L68.9,21.8 C70.1,21.8 71.2,21.8 72,22.4 C72.6,22.8 72.9,23.5 72.9,24.2 C72.9,25 72.6,25.7 72,26.1 C71.2,26.8 69.9,26.8 68.6,26.8 L64.1,26.8 L64.1,21.8 L64.1,21.8 Z M64.1,28.7 L68.5,28.7 L68.7,28.7 C69.5,28.7 70.3,28.7 70.9,28.9 C71,28.9 73.4,29.4 73.4,32 C73.4,33.8 72.2,34.8 70.1,34.9 C69.7,34.9 69.2,34.9 68,34.9 L64,34.9 L64,28.7 L64.1,28.7 Z" />
        <path d="M82.9,24.6 C81.5,24.6 80.5,25.1 79.8,26 L79.8,24.8 L77.9,24.8 L77.9,36.8 L79.8,36.8 L79.8,31.6 L79.8,31.5 C79.8,29.3 79.8,28.1 80.6,27.2 C81.1,26.6 81.9,26.4 82.8,26.4 C83.2,26.4 83.2,26.4 83.6,26.5 L83.9,26.5 L83.9,24.7 L83.7,24.7 C83.4,24.6 83.2,24.6 82.9,24.6 Z" />
        <path d="M94.5,34.9 C94.2,34.6 94.2,34 94.2,32.9 L94.2,29.2 C94.2,28 94.2,27.5 94,26.9 C93.4,24.8 91.1,24.5 89.7,24.5 C87.8,24.5 86.4,25.2 85.7,26.4 C85.2,27.1 85.1,27.9 85,28.5 L85,28.7 L87.1,29.1 L87.1,28.8 C87.1,27.5 87.4,26.3 89.9,26.3 C92.4,26.3 92.4,27.9 92.4,28.7 L92.4,29 L91.2,29.3 C87.6,30.1 84.7,30.8 84.7,33.6 C84.7,34.9 85.5,37 88.5,37 C90.2,37 91.6,36.3 92.4,35.2 C92.8,36.6 94.1,36.7 95.6,36.7 L95.8,36.7 L95.8,35.1 L95.5,35.1 C95,35.2 94.6,35.1 94.5,34.9 Z M92.3,30.8 L92.3,31.3 C92.3,32.5 92.2,33.3 91.4,34.1 C90.7,34.8 89.5,35.3 88.5,35.3 C87,35.3 86.5,34.4 86.5,33.6 C86.5,32.4 87.6,32 89.1,31.6 C89.4,31.5 89.7,31.4 90.2,31.3 C90.6,31.2 91.2,31 91.9,30.8 L92.3,30.8 Z" />
        <polygon points="111.6 33.3 106.1 20 106.1 19.9 103.7 19.9 103.7 36.8 105.8 36.8 105.6 23.5 110.8 36.6 110.8 36.8 112.3 36.8 117.4 23.5 117.3 36.8 119.3 36.8 119.3 19.9 116.9 19.9" />
        <rect x="122.6" y="24.8" width="1.9" height="12" />
        <rect x="122.6" y="19.9" width="1.9" height="2.7" />
        <rect x="127.6" y="19.9" width="1.9" height="16.9" />
        <rect x="132.6" y="19.9" width="1.9" height="2.7" />
        <path d="M132.6,37 C132.6,40.4 132.6,40.5 132.5,40.7 C132.4,40.9 132.3,41.1 131.8,41.1 C131.2,41.1 130.6,40.9 130.1,40.6 L129.7,40.4 L129.7,42.4 L129.8,42.5 C130.5,42.8 131.2,43 131.9,43 C134.2,43 134.4,41.1 134.4,40.4 C134.4,39.5 134.4,36.3 134.4,35.2 L134.4,24.8 L132.5,24.8 L132.5,37 L132.6,37 Z" />
        <path d="M142.3,24.5 C138.5,24.5 136.8,27.7 136.8,30.9 C136.8,34.6 139,37 142.2,37 C143.7,37 145,36.5 145.9,35.5 C147,34.3 147.7,32.6 147.7,30.7 C147.7,27.9 146.3,24.5 142.3,24.5 Z M145.7,30.9 L145.7,30.9 C145.7,32.3 145.3,35.3 142.2,35.3 C140,35.3 138.8,33.7 138.8,31 C138.8,29.4 139.1,28.2 139.8,27.4 C140.4,26.7 141.3,26.3 142.2,26.3 C143.6,26.3 144.6,27 145.2,28.3 C145.6,29 145.7,29.9 145.7,30.9 Z" />
        <polygon points="153.4 33.6 150.4 24.8 148.4 24.8 152.5 36.6 152.5 36.8 154 36.8 158.6 24.8 156.7 24.8" />
        <path d="M169.2,34.9 C168.9,34.6 168.9,34 168.9,32.9 L168.9,29.2 C168.9,28 168.9,27.5 168.7,26.9 C168.1,24.7 165.5,24.5 164.4,24.5 C162.5,24.5 161.1,25.2 160.4,26.4 C159.9,27.1 159.8,27.9 159.7,28.5 L159.7,28.7 L161.8,29.1 L161.8,28.8 C161.8,27.5 162.1,26.3 164.6,26.3 C167.1,26.3 167.1,27.9 167.1,28.7 L167.1,29 L165.9,29.3 C162.3,30.1 159.4,30.8 159.4,33.6 C159.4,34.9 160.2,37 163.2,37 C164.9,37 166.3,36.3 167.1,35.2 C167.5,36.6 168.8,36.7 170.3,36.7 L170.5,36.7 L170.5,35.1 L170.2,35.1 C169.7,35.2 169.3,35.1 169.2,34.9 Z M167,30.8 L167,31.3 C167,32.5 166.9,33.3 166.1,34.1 C165.4,34.8 164.2,35.3 163.2,35.3 C161.7,35.3 161.2,34.4 161.2,33.6 C161.2,32.4 162.3,32 163.8,31.6 C164.1,31.5 164.4,31.4 164.9,31.3 C165.3,31.2 165.9,31 166.6,30.8 L167,30.8 Z" />
        <rect x="172.1" y="19.9" width="1.9" height="16.9" />
        <path d="M139.7,23 C140.3,23 140.8,22.5 140.8,21.8 C140.8,21.2 140.3,20.6 139.7,20.6 C139.1,20.6 138.6,21.1 138.6,21.8 C138.6,22.5 139.1,23 139.7,23 Z" />
        <path d="M144.1,23 C144.7,23 145.2,22.5 145.2,21.8 C145.2,21.2 144.7,20.6 144.1,20.6 C143.5,20.6 143,21.1 143,21.8 C143,22.5 143.4,23 144.1,23 Z" />
      </g>
    </svg>
  )
}
