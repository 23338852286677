import Image from "next/image"
import { useMediaPredicate } from "react-media-hook"
import { truncateString } from "@/lib/helpers/utils"
import Button from "@/common/button/button"
import HeadingLabel from "@/common/partials/headingLabel/headingLabel"

export default function Byline({ byline }) {
  let avatar =
    byline.avatar.length !== 0 ? (
      <div className="flex-none">
        {byline.avatar.url && (
          <figure className="rounded-full aspect-w-16 aspect-h-16 w-icon-xxl h-icon-xxl">
            <Image
              className={`rounded-full object-cover object-center w-full h-full`}
              src={byline.avatar.url}
              sizes="256px"
              alt={byline.avatar.alt}
              layout="fill"
              loading="lazy"
            />
          </figure>
        )}
      </div>
    ) : null

  const sendMail = () => {
    window.location = "mailto:" + byline.email
  }

  let truncatedEmail = byline.email
  const isSmallMobile = useMediaPredicate("(max-width: 320px)")
  const isMobile = useMediaPredicate("(max-width: 640px)")

  if (isSmallMobile) {
    truncatedEmail = truncateString(byline.email, 20)
  } else if (isMobile) {
    truncatedEmail = truncateString(byline.email, 30)
  }

  const noAvatar = avatar ? "" : "w-full justify-between"

  const Phone = () => {
    const links = []

    if (byline.phone) {
      links.push(
        <a
          key="phone"
          href={`tel: ${byline.phone}`}
          className="inline-block text-sm leading-normal">
          {byline.phone}
        </a>
      )

      if (byline.altPhone) {
        links.push(
          <span
            key="separator"
            className="inline-block px-1 text-sm leading-normal">
            /
          </span>
        )
      }
    }

    if (byline.altPhone) {
      links.push(
        <a
          key="alt-phone"
          href={`tel: ${byline.altPhone}`}
          className="inline-block text-sm leading-normal">
          {byline.altPhone}
        </a>
      )
    }

    return links
  }

  return (
    <div className="box-content max-w-screen-sm px-4 mx-auto mb-10 md:mb-15 lg:mb-20 sm:px-10 md:max-w-1xl">
      <div className="flex justify-between pt-3 space-x-5 border-t-2 border-solid border-black-100/10 items-top post-byline-wrapper">
        {avatar}
        <div className={["flex-grow font-sans md:flex", noAvatar].join(" ")}>
          <div className="md:flex-grow text-wrapper">
            <HeadingLabel className="block">{byline.position}</HeadingLabel>
            <span className="block pb-1 font-medium">{byline.name}</span>
            {byline.desc.length > 0 && (
              <span
                className="block pb-2 pr-5 text-sm leading-6 md:text-base md:leading-7"
                dangerouslySetInnerHTML={{ __html: byline.desc }}></span>
            )}
            {byline.email && (
              <div className="max-w-full">
                <a
                  href={`mailto:${byline.email}`}
                  className="text-sm leading-normal">
                  {truncatedEmail}
                </a>
              </div>
            )}
            <Phone />
          </div>

          {byline.email && (
            <div className="mt-4 button-wrapper md:mt-7">
              <Button
                size="small"
                theme="good-green"
                onClick={sendMail}
                className="block no-print">
                Ta kontakt
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
