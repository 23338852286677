import { useEffect, useState } from "react"
import { useHasHero, useIsStart } from "@/lib/helpers/template"
import {
  usePathData,
  usePropsObject,
  useAppContext,
  useAdminBar
} from "@/lib/contexts/appContext"
import Main from "@/components/wrappers/main"
import Breadcrumbs from "@/components/base/breadcrumbs/breadcrumbs"
import Blocks from "@/components/Blocks"
import { ArchiveProvider } from "@/lib/contexts/archiveContext"
import SearchPanel from "@/components/common/search-panel/search-panel"
import styles from "./page.module.css"
import classNames from "classnames/bind"

export default function Page() {
  const pathData = usePathData()
  const appContext = useAppContext()
  const object = usePropsObject()
  const isStart = useIsStart()
  const heroFormat = useHasHero()
  const hasSearch = object.searchPanel ?? false
  const cx = classNames.bind(styles)
  const adminBar = useAdminBar()
  const [classes, setClasses] = useState({
    main: true,
    "has-search": hasSearch,
    "no-hero": !heroFormat
  })

  useEffect(() => {
    setClasses({
      ...classes,
      "no-hero": !heroFormat,
      "has-search": hasSearch,
      "has-admin-bar": adminBar
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathData])

  return (
    <Main className={cx(classes)}>
      {object.searchPanel && (
        <ArchiveProvider context="school" object={object}>
          <SearchPanel />
        </ArchiveProvider>
      )}
      {!isStart && !heroFormat && (
        <Breadcrumbs
          crumbs={appContext.breadcrumbs}
          className="mt-4 mb-5 lg:ml-0"
        />
      )}
      <Content
        isArchive={object?.isArchive}
        object={object}
        pathData={pathData}
      />
    </Main>
  )
}

export function Content({ isArchive, object, pathData }) {
  if (isArchive) {
    return (
      <ArchiveProvider context="on-page" object={object}>
        <Blocks blocks={pathData.content} />
      </ArchiveProvider>
    )
  }

  return <Blocks blocks={pathData.content} />
}
