import Link from "next/link"

export default function Terms({ terms, className = "", omitPrimary = false }) {
  terms = omitPrimary ? terms.filter((term) => !term.isPrimary) : terms

  const items = terms.map((term, key) => {
    const classes = [
      "px-3 py-2 font-sans text-xs font-medium tracking-wider no-underline uppercase rounded whitespace-nowrap leading-1 bg-black-100/10 hover:bg-black-100/20"
    ]

    let link = (
      <Link href={term.url} prefetch={false}>
        <a className={classes.join(" ")}>{term.label}</a>
      </Link>
    )

    if (!term.url) {
      link = <a className={classes.join(" ")}>{term.label}</a>
    }

    return (
      <li className="mb-4 mr-3" key={"article-terms-" + key}>
        {link}
      </li>
    )
  })

  if (items.length === 0) {
    return null
  }

  return (
    <ul
      className={`box-content flex flex-wrap post-terms mx-auto max-w-screen-sm no-print ${className}`}>
      {items}
    </ul>
  )
}
