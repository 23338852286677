export default function Arrow({ className = "" }) {
  return (
    <svg
      viewBox="0 0 13 10"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className={className}>
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinejoin="round">
        <g
          transform="translate(-800.000000, -2524.000000)"
          fill="currentColor"
          stroke="currentColor">
          <g transform="translate(613.000000, 2509.000000)">
            <path d="M194.666667,16 L200,20.0155515 L194.666667,24 L194.666667,20.6666667 L188,20.6666667 L188,19.3333333 L194.666667,19.3333333 L194.666667,16 Z"></path>
          </g>
        </g>
      </g>
    </svg>
  )
}
