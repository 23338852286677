import Image from "next/image"
import styles from "./product.module.css"
import Arrow from "@/common/icons/arrow"

export default function Product({ post }) {
  if (!post) {
    return null
  }

  const { tag, icon, heading, label, url, licenseDate, licenseHolder } = post

  return (
    <div className={styles.card}>
      <div className="inner-wrapper">
        <div className="icon">
          <figure>
            {icon && (
              <Image
                src={icon}
                layout="fill"
                objectFit="contain"
                objectPosition="center"
              />
            )}
          </figure>
        </div>
        {tag && (
          <h6>
            <a href={tag.url}>{tag.label}</a>
          </h6>
        )}
        <h4>{heading}</h4>
        <div className="text">
          <span>{licenseHolder}</span>
          <span>{licenseDate}</span>
        </div>
      </div>

      <a className="link" href={url}>
        <span>{label}</span>
        <div className="square">
          <Arrow className="w-4 h-4 mx-auto" />
        </div>
      </a>
    </div>
  )
}
